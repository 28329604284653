import { useEffect } from 'react'

// import React, { useState } from 'react'
// import IndexLayout from 'site/src/components/org.layout/layout.component'
// import { Button, Grid, Row, Separator } from 'atomic'
// import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
// import { graphql } from 'gatsby'
// import { PrivacyContainer, AccordionsContainer, ChannelsContainer } from './styled'
// import Banner from './banner'
// import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
// import Accordion from 'site/src/components/Accordion'

// import { TextField, MenuItem } from '@material-ui/core'

export default props => {  

  useEffect(() => {
    window.open('https://privacyportal.onetrust.com/policies/26edb1e8-78ab-465b-9129-d0d9cf1f833e?context=eyJkc1BvcnRhbElkIjoiMGFjZGE4ODgtNDNlZS00OTdjLTk4MmQtMTExMmE2Y2ZhM2NkIn0%3D', '_blank')
    window.history.back()
  }, [])

  return (null)
  
  // const [language, setLanguage] = useState('br')

  // const changeLanguage = lang => {
  //   setLanguage(lang)
  //   setPersonaSelected({ label: '', url: '' })
  // }
  // const [expanded, setExpanded] = useState(false)

  // const [personaSelected, setPersonaSelected] = useState({ label: '', url: '' })
  // const { metadata } = props.data.cosmicjsLandingPages

  // const onChangePersona = persona => {
  //   if (language === 'br') {
  //     const person = metadata.secao_canais_de_atendimento.opcoes_de_selecao.find(
  //       f => f.label === persona
  //     )
  //     setPersonaSelected({ label: person.label, url: person.url })
  //   } else {
  //     const person = metadata.secao_canais_de_atendimento.opcoes_de_selecao.find(
  //       f => f.label_ingles === persona
  //     )

  //     setPersonaSelected({ label: person.label_ingles, url: person.url_ingles })
  //   }
  // }

  // const handleChange = panel => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false)
  // }

  // return (
  //   <IndexLayout location={props.location}>
  //     <SEO
  //       socialMedia={{
  //         //   canonicalUrl: window.location.href,
  //         title: 'Portal de Privacidade',
  //         image: metadata.banner_destaque.imagem_destaque.url,
  //         imageAlt: 'Portal de Privacidade',
  //         description: metadata.banner_destaque.descricao
  //       }}
  //     />
  //     <Grid>
  //       <TitleWithBreadcrumbRow
  //         addtionalDictionary={{
  //           '/portal-de-privacidade':
  //             language === 'br'
  //               ? metadata.banner_destaque.titulo
  //               : metadata.banner_destaque.titulo_ingles
  //         }}
  //       />
  //     </Grid>

  //     <PrivacyContainer>
  //       <Grid col={6}>
  //         <Row>
  //           <Banner
  //             {...metadata.banner_destaque}
  //             language={language}
  //             setLanguage={changeLanguage}
  //           />
  //         </Row>
  //       </Grid>
  //     </PrivacyContainer>
  //     <AccordionsContainer>
  //       <Grid>
  //         <Row>
  //           {metadata.faq
  //             .filter(x => x.channels.find(c => c === 'Website'))
  //             .map(({ question, question_ingles, answer, answer_ingles }, index) => {
  //               return (
  //                 <Accordion
  //                   bgWhite
  //                   key={index}
  //                   slug={index.toString()}
  //                   title={
  //                     language === 'br'
  //                       ? `${index + 1}. ${question}`
  //                       : `${index + 1}. ${question_ingles}`
  //                   }
  //                   expanded={expanded}
  //                   handleChange={handleChange}
  //                 >
  //                   <p variant="body1">{language === 'br' ? answer : answer_ingles}</p>
  //                 </Accordion>
  //               )
  //             })}
  //         </Row>
  //       </Grid>
  //     </AccordionsContainer>
  //     <ChannelsContainer>
  //       <Grid>
  //         <Row>
  //           <div className="channels">
  //             <p>
  //               <span className="channels-title">
  //                 {language === 'br'
  //                   ? metadata.secao_canais_de_atendimento.titulo_da_secao
  //                   : metadata.secao_canais_de_atendimento.titulo_da_secao_ingles}
  //               </span>
  //             </p>
  //             <p>
  //               {language === 'br'
  //                 ? metadata.secao_canais_de_atendimento.subtitulo
  //                 : metadata.secao_canais_de_atendimento.subtitulo_ingles}
  //             </p>
  //           </div>
  //         </Row>

  //         <Row mb center="xs" className="no-padding-bottom">
  //           <TextField
  //             select
  //             variant="outlined"
  //             label={language === 'br' ? 'Selecione o perfil de usuário' : 'Select User Profile'}
  //             fullWidth
  //             onChange={e => onChangePersona(e.target.value)}
  //           >
  //             {metadata.secao_canais_de_atendimento.opcoes_de_selecao.map(item =>
  //               language === 'br' ? (
  //                 <MenuItem key={item.label} value={item.label}>
  //                   {item.label}
  //                 </MenuItem>
  //               ) : (
  //                 <MenuItem key={item.label_ingles} value={item.label_ingles}>
  //                   {item.label_ingles}
  //                 </MenuItem>
  //               )
  //             )}
  //           </TextField>
  //         </Row>
  //         <Row mb center="xs">
  //           <div
  //             dangerouslySetInnerHTML={{
  //               __html:
  //                 personaSelected.label !== ''
  //                   ? language === 'br'
  //                     ? metadata.secao_canais_de_atendimento.texto_embaixo_de_opcoes.replace(
  //                         '#PERSONA#',
  //                         personaSelected.label?.toUpperCase().split(':', 1)
  //                       )
  //                     : metadata.secao_canais_de_atendimento.texto_embaixo_de_opcoes_ingles.replace(
  //                         '#PERSONA#',
  //                         personaSelected.label?.toUpperCase().split(':', 1)
  //                       )
  //                   : ''
  //             }}
  //           />
  //         </Row>
  //         <Row mb>
  //           <a
  //             href={personaSelected.url !== '' ? personaSelected.url : '#'}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             <Button
  //               id="button-send-email-contact"
  //               kind="secondary"
  //               expanded
  //               //loading={state.loading}

  //               disabled={personaSelected.label !== '' ? false : true}
  //             >
  //               {language === 'br' ? `Ir para formulário` : 'Go to form'}
  //             </Button>
  //           </a>
  //           <Separator />
  //         </Row>

  //         <div
  //           className="privacy-content"
  //           dangerouslySetInnerHTML={{
  //             __html: language === 'br' ? metadata.message_footer : metadata.message_footer_ingles
  //           }}
  //         />
  //       </Grid>
  //     </ChannelsContainer>
  //   </IndexLayout>
  // )
}

// export const query = graphql`
//   query Privacy {
//     cosmicjsLandingPages(slug: { eq: "portal-de-privacidade" }) {
//       id
//       metadata {
//         banner_destaque {
//           titulo
//           titulo_ingles
//           imagem_destaque {
//             url
//           }
//           descricao
//           descricao_ingles
//         }

//         faq {
//           question
//           question_ingles
//           answer
//           answer_ingles
//           channels
//         }
//         secao_canais_de_atendimento {
//           titulo_da_secao
//           titulo_da_secao_ingles
//           subtitulo
//           subtitulo_ingles
//           opcoes_de_selecao {
//             label
//             label_ingles
//             url
//             url_ingles
//           }
//           texto_embaixo_de_opcoes
//           texto_embaixo_de_opcoes_ingles
//         }
//         message_footer
//         message_footer_ingles
//       }
//     }
//   }
// `
